import React, { useState, useEffect } from 'react';
import { Container, Typography, Alert, CircularProgress } from '@mui/material';
import Canvas from '../components/Canvas';
import axios from '../api/axios';

const DesignCard = () => {
  const [templates, setTemplates] = useState([]);  // Estado para las plantillas
  const [selectedTemplate, setSelectedTemplate] = useState('');  // Estado para la plantilla seleccionada
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  // Cargar todas las plantillas al iniciar el componente
  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        setLoading(true);
        const response = await axios.get('/api/templates');  // Llamada a la API para obtener todas las plantillas
        setTemplates(response.data);
        setError('');
      } catch (err) {
        console.error('Error fetching templates:', err);
        setError('Error fetching templates: ' + (err.response?.data?.message || err.message));
      } finally {
        setLoading(false);
      }
    };
    fetchTemplates();
  }, []);

  // Función para cargar una plantilla específica
  const handleLoadTemplate = async () => {
    if (!selectedTemplate) return;
    try {
      setLoading(true);
      const response = await axios.get(`/api/templates/${selectedTemplate}`);  // Llamada a la API para cargar una plantilla por ID
      console.log(response.data);  // Implementación para cargar los datos en el canvas
      setError('');
    } catch (error) {
      console.error('Error loading template:', error);
      setError('Error loading template: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>Design Card</Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {/* Listado de plantillas */}
          <Typography variant="h6">Available Templates</Typography>
          <select onChange={(e) => setSelectedTemplate(e.target.value)} value={selectedTemplate}>
            <option value="">Select a template</option>
            {templates.map((template) => (
              <option key={template._id} value={template._id}>
                {template.name}
              </option>
            ))}
          </select>
          <button onClick={handleLoadTemplate}>Load Template</button>

          {/* Componente Canvas para diseñar el carnet */}
          <Canvas />
        </>
      )}
    </Container>
  );
};

export default DesignCard;
