import React from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton } from '@mui/material';
import { Link } from 'react-router-dom';
import Brightness4Icon from '@mui/icons-material/Brightness4';  // Icono para modo oscuro
import Brightness7Icon from '@mui/icons-material/Brightness7';  // Icono para modo claro

// El componente Header recibe ahora las props toggleTheme y currentTheme
const Header = ({ toggleTheme, currentTheme }) => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          ID Card Platform
        </Typography>
        <Button color="inherit" component={Link} to="/">Home</Button>
        <Button color="inherit" component={Link} to="/design">Design Card</Button>
        <Button color="inherit" component={Link} to="/verify">Verify Card</Button>
        <Button color="inherit" component={Link} to="/dashboard">Dashboard</Button>
        <Button color="inherit" component={Link} to="/login">Login</Button>
        <Button color="inherit" component={Link} to="/register-user">Register User</Button>
        <Button color="inherit" component={Link} to="/register-client">Register Client</Button>
        <Button color="inherit" component={Link} to="/profile">Profile</Button>
        <Button color="inherit" component={Link} to="/settings">Settings</Button>

        {/* Botón de cambio de tema */}
        <IconButton
          color="inherit"
          onClick={toggleTheme}  // Ejecuta la función para alternar entre claro/oscuro
        >
          {currentTheme === 'light' ? <Brightness4Icon /> : <Brightness7Icon />}
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
