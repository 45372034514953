import { createTheme } from '@mui/material/styles';

// Tema para modo claro
const lightTheme = {
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#555555',
    },
  },
};

// Tema para modo oscuro
const darkTheme = {
  palette: {
    primary: {
      main: '#bb86fc',
    },
    secondary: {
      main: '#03dac6',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: '#bbbbbb',
    },
  },
};

// Función para cambiar entre los dos temas
const theme = (mode) => createTheme(mode === 'dark' ? darkTheme : lightTheme);

export default theme;
