import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Stage, Layer, Text, Image as KonvaImage, Rect, Circle, Transformer } from 'react-konva';
import useImage from 'use-image';
import { saveAs } from 'file-saver';
import axios from '../api/axios';
import '/src/styles/Canvas.css';
import MenuIcon from './menu-icon-component.tsx';
import Accordion from '@mui/material/Accordion'; 
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Checkbox from '@mui/material/Checkbox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const Canvas = () => {
  const [elements, setElements] = useState([]);
  const [history, setHistory] = useState([]);
  const [selectedElement, setSelectedElement] = useState(null);
  const [categories, setCategories] = useState([]); 
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [showCategories, setShowCategories] = useState(false); // Estado para mostrar u ocultar categorías
  const [showTextOptions, setShowTextOptions] = useState(false); // Estado para mostrar u ocultar panel de texto

  // Estados para gestionar las propiedades del texto
  const [fontSize, setFontSize] = useState(24); // Tamaño de texto por defecto
  const [textColor, setTextColor] = useState('#000000'); // Color por defecto
  const [textElements, setTextElements] = useState([]); // Lista de elementos de texto

  const stageRef = useRef(null);
  const trRef = useRef(null);

  // Cargar las categorías desde el archivo JSON
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get('https://dev.verifyidcard.com/data/categories.json'); // Ruta del archivo de categorías
        setCategories(response.data); // Cargar las categorías desde el archivo
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
    fetchCategories();
  }, []);

  // Función para manejar la selección de categorías y subcategorías
  const handleCategorySelect = (category) => {
    if (selectedCategories.includes(category)) {
      setSelectedCategories(selectedCategories.filter((cat) => cat !== category));
    } else {
      setSelectedCategories([...selectedCategories, category]);
    }
  };

  // Función para mostrar u ocultar las categorías
  const toggleCategories = () => {
    setShowCategories(!showCategories);
  };

   // Función para mostrar u ocultar el panel de texto
  const toggleTextOptions = () => {
    setShowTextOptions(!showTextOptions);
    console.log("Text options toggled:", showTextOptions); // Agregado para depurar
  };

  // Función para manejar el cambio del tamaño de la fuente
  const handleFontSizeChange = (e) => {
    setFontSize(e.target.value);
  };

  // Función para manejar el cambio de color del texto
  const handleTextColorChange = (e) => {
    setTextColor(e.target.value);
  };

  // Función para agregar texto al lienzo
  const handleSaveText = () => {
    const newTextElement = {
        type: 'text',
        x: 50,
        y: 50,
        text: 'Nuevo Texto',
        fontSize: fontSize,
        fill: textColor,
        draggable: true,
    };
    setTextElements([...textElements, newTextElement]);
  };

  // Renderizado de los elementos de texto en el lienzo
  {textElements.map((el, i) => (
    <Text
        key={i}
        {...el}
        onClick={(e) => handleSelect(e, el)}
        onDragEnd={(e) => handleElementDragEnd(e, el.id)}
    />
  ))}

  return (
    <div className="canvas-container">
      <div className="menu-area">
        {/* Íconos del menú principal */}
        <MenuIcon name="id-card-categories" alt="ID Card Categories" onClick={toggleCategories} />
        <MenuIcon name="templates" alt="Templates" />
        <MenuIcon name="text" alt="Texto" onClick={toggleTextOptions} />
        <MenuIcon name="images" alt="Images" />
        <MenuIcon name="shapes" alt="Shapes" />
        <MenuIcon name="cliparts" alt="Cliparts" />
        <MenuIcon name="qr-code" alt="QR Codes" />
        <MenuIcon name="layers" alt="Layers" />
        <MenuIcon name="setting" alt="Setting" />
        <MenuIcon name="my-designs" alt="My Design" />
      </div>

      {/* Mostrar el panel de categorías al hacer clic en el ícono */}
      {showCategories && (
        <div className="categories-panel">
          <h3>Categorías de Carnets</h3>
          <Accordion>
            {categories.map((category) => (
              <Accordion key={category.name}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Checkbox
                    checked={selectedCategories.includes(category.name)}
                    onChange={() => handleCategorySelect(category.name)}
                  />
                  {category.name}
                </AccordionSummary>
                <AccordionDetails>
                  {category.subcategories.map((subcategory) => (
                    <div key={subcategory}>
                      <Checkbox
                        checked={selectedCategories.includes(subcategory)}
                        onChange={() => handleCategorySelect(subcategory)}
                      />
                      {subcategory}
                    </div>
                  ))}
                </AccordionDetails>
              </Accordion>
            ))}
          </Accordion>
        </div>
      )}

       {/* Mostrar el panel de texto al hacer clic en el ícono */}
      {showTextOptions && (
        <div className="text-options-panel">
        <h3>Opciones de Texto</h3>
        <label>Fuente:</label>
        <select>
            <option>Arial</option>
            {/* Añade más opciones de fuentes si es necesario */}
        </select>

        <label>Tamaño del Texto:</label>
        <input type="number" value={fontSize} onChange={handleFontSizeChange} />

        <label>Color del Texto:</label>
        <input type="color" value={textColor} onChange={handleTextColorChange} />

        <button onClick={handleSaveText}>Guardar</button>
       </div>
      )}

      {/* Área de trabajo del Canvas */}
      <div className="work-area">
        <Stage width={800} height={500} ref={stageRef}>
          <Layer>
            {elements.map((el, i) => {
              switch (el.type) {
                case 'text':
                  return (
                    <Text
                      key={i}
                      {...el}
                      onClick={(e) => handleSelect(e, el)}
                      onDragEnd={(e) => handleElementDragEnd(e, el.id)}
                    />
                  );
                case 'image':
                  return (
                    <CanvasImage
                      key={i}
                      imageUrl={el.url}
                      {...el}
                      onClick={(e) => handleSelect(e, el)}
                      onDragEnd={(e) => handleElementDragEnd(e, el.id)}
                    />
                  );
                case 'rect':
                  return (
                    <Rect
                      key={i}
                      {...el}
                      onClick={(e) => handleSelect(e, el)}
                      onDragEnd={(e) => handleElementDragEnd(e, el.id)}
                    />
                  );
                case 'circle':
                  return (
                    <Circle
                      key={i}
                      {...el}
                      onClick={(e) => handleSelect(e, el)}
                      onDragEnd={(e) => handleElementDragEnd(e, el.id)}
                    />
                  );
                default:
                  return null;
              }
            })}
            <Transformer ref={trRef} />
          </Layer>
        </Stage>
      </div>
    </div>
  );
};

export default Canvas;
